<template>
	<el-dialog :title="!dataForm.id ? '加班记录添加' : '加班记录修改'" :close-on-click-modal="false" :visible.sync="visible"
		width="480px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="110px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="人员" prop="workMan" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.workMan" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="加班日期" prop="overtimeDate"  class="item">
						<el-date-picker class="selItemInput" v-model="dataForm.overtimeDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="时长(小时)" prop="overtimeHours" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.overtimeHours"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "overtime-add-or-update",
	data() {
		return {
			id: "",
			visible: true,
			form: {},
			action: "",
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				id: "",
				workMan: "",
				overtimeHours: "",
				overtimeDate: ""

			},
			dataRule: {
				workMan: [
					{ required: true, message: " ", trigger: "blur" }
				],
				overtimeDate: [
					{ required: true, message: " ", trigger: "blur" }
				],
				overtimeHours: [
					{ required: true, message: " ", trigger: "blur" }
				]
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				id: "",
				workMan: "",
				overtimeHours: "",
				overtimeDate: ""
			};
			this.dataForm.id = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/overtimeinfo/info/" + this.dataForm.id,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
				}
			});
		},
		dataFormSubmit() {
			if (this.dataForm.workMan == null || this.dataForm.workMan == "") {
				this.$message.error("加班人员不能为空！");
				return;
			}
			if (this.dataForm.overtimeDate == null || this.dataForm.overtimeDate == "") {
				this.$message.error("加班日期不能为空！");
				return;
			}
			if (this.dataForm.overtimeHours == null || this.dataForm.overtimeHours == "") {
				this.$message.error("加班时长不能为空！");
				return;
			}
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/overtimeinfo/save";
					if (this.dataForm.id && this.dataForm.id != 0) {
						_url = "/business/overtimeinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
